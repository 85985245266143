'use client';
import * as React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, VisibilityOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import ThemeRegistry from '@/lib/components/ThemeRegistry/ThemeRegistry';
import { MainNavigation } from './navigation';
import { QueryProviders } from './queryProviders';
import { AuthControlledContent } from './authGateway';
import { DRAWER_WIDTH, NAVBAR_HEIGHT } from './lib/consts';

export const metadata = {
  title: 'EIDOS',
  description: 'Base Form Anonymization',
};

const FullLayout = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeRegistry>
      <AuthControlledContent>
        <AppBar position="fixed" sx={{}}>
          <Toolbar sx={{ backgroundColor: 'background.paper' }}>
            {!drawerOpen ? (
              <ArrowForwardIos
                sx={{ color: '#444', marginLeft: -2 }}
                onClick={toggleDrawer}
              />
            ) : null}
            <VisibilityOff
              sx={{
                color: '#444',
                mr: 2,
                transform: 'translateY(-2px)',
                ml: !drawerOpen ? 2 : 0,
              }}
            />
            <Typography variant="h6" color="text.primary">
              {metadata.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <QueryProviders>
          <MainNavigation open={drawerOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: 'background.default',
              ml: drawerOpen ? `${DRAWER_WIDTH}px` : 0,
              mt: `${NAVBAR_HEIGHT}px`,
              p: 3,
            }}
          >
            {children}
          </Box>
        </QueryProviders>
      </AuthControlledContent>
    </ThemeRegistry>
  );
};

export default function ClientLayout({ children }: { children: React.ReactNode }) {
  return <FullLayout>{children}</FullLayout>;
}
