"use client";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import {
  COGNITO_USER_POOL_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from "./lib/config/client";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_CLIENT_ID,
    },
  },
});

export const AuthControlledContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Authenticator.Provider>
      <Authenticator loginMechanisms={["email"]} hideSignUp={true}>
        {({ signOut, user }) => <>{children}</>}
      </Authenticator>
    </Authenticator.Provider>
  );
};
