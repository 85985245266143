import Link from "next/link";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Home as HomeIcon,
  Logout as LogoutIcon,
  ListAlt,
  Policy,
  ArrowBack,
  ArrowBackIos,
} from '@mui/icons-material';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const metadata = {
  title: 'EIDOS',
  description: 'Base Form Anonymization',
};

const LINKS = [
  { text: 'Dashboard', href: '/', icon: HomeIcon },
  { text: 'Projects', href: '/projects', icon: ListAlt },
  { text: 'Review', href: '/review', icon: Policy },
];

export const MainNavigation = ({
  open,
  toggleDrawer,
}: {
  open: boolean;
  toggleDrawer: () => void;
}) => {
  const { signOut } = useAuthenticator();
  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          top: ['48px', '56px', '64px'],
          height: 'auto',
          bottom: 0,
        },
      }}
      open={open}
      variant="persistent"
      anchor="left"
    >
      <Divider />
      <List>
        {LINKS.map(({ text, href, icon: Icon }) => (
          <ListItem key={href} disablePadding>
            <ListItemButton component={Link} href={href}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ mt: 'auto' }} />
      <List>
        <ListItem key={'Hide'} disablePadding>
          <ListItemButton onClick={() => toggleDrawer()}>
            <ListItemIcon>
              <ArrowBackIos />
            </ListItemIcon>
            <ListItemText primary={'Hide'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'Logout'} disablePadding>
          <ListItemButton onClick={() => signOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};
